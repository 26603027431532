// Here you can add other styles
@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.sidebar .nav-dropdown.open {
  background: rgba(0, 153, 0, 0.4);
}

.sidebar .nav-link.active {
  color: #fff;
  background: rgba(255, 0, 0, 0.2);
}

.react-datepicker-wrapper {
  width: 100%;
}

.inlineForm .row {
  padding-bottom: 4px;
}

.hdrButton {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 0px !important;
}

.aqsActionsButton {
  padding: 0px !important;
  border: 0px !important;
}

.shortButton {
  padding: 0px !important;
}

.card .pt-studies {
  background-color: #eeeef0 !important;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 2px 1px 1px 5px !important;
}

.deleteLink.btn-link {
  color: #ff2e00 !important;
}

/* .custom-control-label::before,
.custom-control-label::after {
  top: 0.8rem;
  width: 2.5rem;
  height: 2.5rem;
} */

.custom-control-lg .custom-control-label::before,
.custom-control-lg .custom-control-label::after {
  top: 0.1rem !important;
  left: -2rem !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.custom-control-lg .custom-control-label {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
}

.ql-container {
  height: auto !important;
}

.ql-editor {
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.modal-header.confirmAlert {
  background-color: #ffc107;
  color: white;
}

.modal-header.primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.password-input {
  border: 3px solid #ccc;
  color: #616161;
  outline: 0;
  padding: 7px;
  font-size: 1em;
  min-width: 250px;
  border-radius: 5px;
  background: url("/password-lock.png") no-repeat 10px 8px;
  background-size: 17px 17px;
  margin-left: 15px;
  padding-left: 30px;
}

.ab-image {
  height: 400px;
  width: 400px;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

// accessibility changes
.navbar-nav .nav-link {
  color: #1b1f23 !important;
}

.btn-link {
  color: #0071bc;
}

.a {
  color: #0071bc !important;
}

.sidebar .nav-title {
  color: #1b1f23 !important;
}

.badge-info {
  color: #1b1f23 !important;
}

.sidebar .nav-link.disabled {
  color: #1b1f23 !important;
}

.text-muted {
  color: #212529 !important;
}

.btn-primary {
  background-color: #167394;
}

.btn-link {
  color: #167394;
}

.rt-td a {
  color: #043469;
}

.breadcrumb-item.active {
  color: #212529 !important;
}

.navbar.chk {
  display: block;
}

.badge.chkSection {
  display: block;
  font-size: 18px;
}

.badge.chkCategory {
  display: block;
  font-size: 14px;
  padding: 0.4em 0.4em;
}

a.list-group-item:hover {
  cursor: pointer;
}

.sigCanvas {
  width: 100%;
  height: 200px;
}

.actionButton {
  padding-left: 3px;
  padding-right: 3px;
}

.popover-body {
  // background-color: coral;
  // color: #FFFFFF !important;
  padding-left: 35px !important;
  .big-checkbox {width: 18px; height: 18px;}
}

// milestone modal sort **
.sortlist {
  user-select: none;
}

.sortitem {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(84, 84, 241);
  color: white;
  margin: 8px;
  height: 60px;
  cursor: grab;
}

.dragged {
  background-color: rgb(37, 37, 197);
}

@media (min-width: 600px) {
  .sortlist {
    grid-gap: 24px;
  }
}
// **

.sidebar .nav-link .nav-icon {
  color: white !important;
}

.list-group-scroll{
  max-height: 300px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}

.pp .ql-editor{
  // height: auto;
  max-height:400px;
  font-size: 16px;
  overflow-x: hidden;
}

.pp .card{
  // height: auto;
  height:400px !important;
}

.chart-container {
  height: 400px;
}

.acc-element-1 {
  min-width: 45px;
  min-height: 45px;
}

p {
  line-height: 1.5;
}



.chkButton:hover {
  color: inherit !important;
  text-decoration: none !important;
  background-color:transparent !important;
}